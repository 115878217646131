import React, { useState } from "react";
import Modal from "../index";
import styles from "./index.module.scss";
import Input from "../../widgets/Input";
import { IoMdClose } from "react-icons/io";
import user from "../../assets/user.png";
import ButtonTwo from "../../widgets/ButtonTwo";
import axios from "axios";
import Loader from "../../components/Loader";
import SweetAlert from "../../shared/SweetAlert";
import ReactCropper from "../../shared/Cropper";
import { useDispatch, useSelector } from "react-redux";
import { authAction } from "../../utils/reducers/auth";
import { useTranslation } from "react-i18next";
import { endpoints } from "../../services/endpoints";

const Profile = (props) => {
  const { t } = useTranslation();
  const userdetails = useSelector((state) => state.auth.userDetails);
  const loading = useSelector((state) => state.auth.profileLoading);
  const dispatch = useDispatch();

  const [upload, setUpload] = useState(userdetails.profileImg);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [croppedImg, setCroppedImg] = useState("");
  const [croppedImgUrl, setCroppedImgUrl] = useState("");
  const [showCropper, setShowCropper] = useState(false);
  const [userData, setUserData] = useState({
    name: userdetails.name,
    phone: userdetails.phone,
  });

  const accessToken = localStorage.getItem("access_token");

  const onUploadProfilePicture = (e) => {
    let imgPath = e.target.files[0];
    let imgUrl = URL.createObjectURL(imgPath);

    if (imgUrl) {
      setUpload(imgUrl);
      setShowCropper(true);
    }
  };

  const onUpdateData = (e, id) => {
    setUserData((prevState) => ({
      ...prevState,
      [id]: e.target.value,
    }));
  };

  const stylesRefer = {
    textTransform: "uppercase",
    letterSpacing: "2px",
    color: "#177ee7",
  };

  console.log(croppedImg);

  const saveProfile = () => {
    setUpdateLoading(true);
    if (userData.name === "" || userData.name === null) {
      SweetAlert("warning", "Name must not be blank");
      setUpdateLoading(false);
    } else if (userData.name?.length < 3) {
      SweetAlert("warning", "Name must be greater than 3 character");
      setUpdateLoading(false);
    } else if (userData.phone === "") {
      SweetAlert("warning", "Phone no must not be blank");
      setUpdateLoading(false);
    } else {
      console.log(croppedImg);
      const formData = new FormData();
      formData.append("first_name", userData.name);
      formData.append("phone", userData.phone);
      formData.append("profileImg", croppedImg);
      formData.append("name", userData.name);

      const headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json",
      };

      axios
        .post(endpoints.profile.updateProfile, formData, {
          headers: headers,
        })
        .then((result) => {
          if (result.data.status === true) {
            console.log(result.data);
            SweetAlert("success", "Profile updated successfully");
            dispatch(authAction.triggeredProfileDetails());
            setUpload(result.data.profileImg);
            setUpdateLoading(false);
          } else if (result.data.status === false) {
            SweetAlert("error", "Profile not updated successfully");
          }
        })
        .catch((err) => {
          setUpdateLoading(false);
          SweetAlert("error", "userdata not found  here");
          console.log(err);
        });
    }
  };

  const onModalClose = () => {
    setShowCropper(false);
  };

  const onSaveHandler = () => {
    setUpload(croppedImgUrl);
    setShowCropper(false);
  };

  return (
    <>
      {showCropper && (
        <Modal zIndex2="9998" zIndex="9999">
          <ReactCropper
            onModalClose={onModalClose}
            onSaveHandler={onSaveHandler}
            imgURL={upload}
            setCroppedImgUrl={setCroppedImgUrl}
            setCroppedImg={setCroppedImg}
          />
        </Modal>
      )}
      <Modal>
        <div className={styles.profile}>
          <div className={styles.profile__top}>
            <span onClick={props.onClose} className={styles.profile__top__close}>
              <IoMdClose />
            </span>
          </div>
          {loading ? (
            <div className={styles.loader}>
              <Loader />
            </div>
          ) : (
            <>
              <div className={styles.profile__profilepic}>
                <label htmlFor="uploading">+</label>
                <img src={upload ? upload : user} alt="user" />
                <div className={styles.profile__profilepic__input}>
                  <input onChange={onUploadProfilePicture} id="uploading" type="file" accept="*" />
                </div>
              </div>
              <div className={styles.profile__mid}>
                <Input onChange={(e) => onUpdateData(e, "name")} id="name" type="text" label="Full Name" value={userData.name} />
                <Input type="email" disabled={true} label="Email Id" value={userdetails.email} />
                <Input onChange={(e) => onUpdateData(e, "phone")} id="phone" type="phone" label="Phone Number" value={userData.phone} />
                <Input style={stylesRefer} id="refferal" type="text" disabled={true} label="Refferal Code" value={userdetails.own_referral_code} />
              </div>

              <ButtonTwo name={t("booking.update")} onClick={saveProfile} loading={updateLoading} />
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default Profile;
