import React, { forwardRef, useEffect, useState } from "react";
import Title from "../Title";
import styles from "./index.module.scss";
import { FaStar } from "react-icons/fa";
import Button from "../../../widgets/Button";
import AllReviews from "../AllReviews";
import axios from "axios";
import { useParams } from "react-router-dom";
import SweetAlert from "../../../shared/SweetAlert";
import { useTranslation } from "react-i18next";
import { endpoints } from "../../../services/endpoints";

const Reviews = forwardRef((props, ref) => {
  const params = useParams();
  const { t } = useTranslation();
  const [filter, setFilter] = useState([]);
  const [isLess, setIsLess] = useState(true);
  const [allReviews, setAllReviews] = useState([]);
  const [star, setStar] = useState(1);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const showLess = () => {
    setFilter(allReviews?.slice(0, 2));
    setIsLess(true);
  };

  const showMore = () => {
    setFilter(allReviews);
    setIsLess(false);
  };

  const onAddReview = (e) => {
    setMessage(e.target.value);
  };

  const reviewapi = endpoints.review.reviewByCategory + `${atob(params.prodId)}`;

  const getReview = () => {
    axios
      .get(reviewapi)
      .then((res) => {
        if (res.data.status === true) {
          let val = res.data.body;
          val = val.reverse();
          setFilter(val.slice(0, 2));
          setAllReviews(val);
        } else if (res.data.status === false) {
          // toast(res.data.message, { type: "error" });
        }
      })
      .catch((err) => {
        console.log(err, "Review Details Not Found");
      });
  };

  useEffect(() => {
    getReview();
    // eslint-disable-next-line
  }, []);

  const onSubmitAReview = () => {
    setLoading(true);
    const access_token = localStorage.getItem("access_token");
    const writeReviewApi = endpoints.review.writeReview;

    const reviewData = {
      package_id: atob(params.prodId),
      model_type: "package",
      rating: star,
      description: message,
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };

    if (access_token) {
      axios
        .post(writeReviewApi, reviewData, { headers: headers })
        .then((res) => {
          if (res.data.status === true) {
            getReview();
            setLoading(false);
            setMessage(" ");
            SweetAlert("success", "Review Submitted successfully");
          } else if (res.data.status === false) {
            SweetAlert("error", res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          SweetAlert("error", "Review details data not found");
          setLoading(false);
        });
    } else {
      SweetAlert("warning", "Please Login");
      setLoading(false);
    }
  };

  return (
    <div ref={ref} className={styles.reviews}>
      <div className={styles.reviews__content}>
        <div className={styles.reviews__content__left}>
          <Title title={t("details.writeR")} />
          <h5>{t("details.star")}</h5>
          <div className={styles.reviews__content__left__star}>
            <FaStar onClick={() => setStar(1)} color="#ffd700" className={styles.reviews__content__left__star__icon} />
            <FaStar onClick={() => setStar(2)} color={[2, 3, 4, 5].includes(star) ? "#ffd700" : ""} className={styles.reviews__content__left__star__icon} />
            <FaStar onClick={() => setStar(3)} color={[3, 4, 5].includes(star) ? "#ffd700" : ""} className={styles.reviews__content__left__star__icon} />
            <FaStar onClick={() => setStar(4)} color={[4, 5].includes(star) ? "#ffd700" : ""} className={styles.reviews__content__left__star__icon} />
            <FaStar onClick={() => setStar(5)} color={star === 5 ? "#ffd700" : ""} className={styles.reviews__content__left__star__icon} />
          </div>

          <p>{t("details.writeD")}</p>
          <textarea value={message} onChange={onAddReview} placeholder={t("details.writeYourR")}></textarea>
          <div>
            <Button onClick={onSubmitAReview} name={t("details.submitR")} loading={loading} />
          </div>
        </div>

        <div className={styles.reviews__content__right}>
          <AllReviews data={filter} />
          {allReviews.length > 0 && <>{isLess ? <p onClick={showMore}>{t("details.viewMore")}</p> : <p onClick={showLess}>{t("details.viewLess")}</p>}</>}
        </div>
      </div>
    </div>
  );
});

export default Reviews;
