import React from "react";
import Title from "../Title";
import SocialLinks from "../../SocialLinks";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const NeedHelp = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const onNavigateToTerms = () => {
    navigate("/terms-and-condition");
  };
  return (
    <div style={{ textAlign: `${i18n.language === "ar" ? "right" : "left"}` }} className={styles.needHelp}>
      <Title title={t("help")} />
      <button className={styles.needHelp__btn}>
        <a href="https://admin.xperienceit.in/page/become-a-vendor" rel="noreferrer">
          {t("merchant")}
        </a>
      </button>
      <p onClick={onNavigateToTerms} className={styles.needHelp__terms}>
        {t("terms")}
      </p>
      <SocialLinks />
    </div>
  );
};

export default NeedHelp;
