import React, { useEffect, useRef } from "react";
import styles from "./index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { uiActions } from "../../utils/reducers/ui";
import Acordian from "../Accordian";
import prof from "../../assets/user.png";
import { useTranslation } from "react-i18next";
import Dashboard from "../../modals/Dashboard";
import { useNavigate } from "react-router-dom";
import { authAction } from "../../utils/reducers/auth";
import Button from "../../widgets/Button";

const MobileNave = ({ onLoginOpen, profile }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  let user = JSON.parse(localStorage.getItem("userDetails"));

  const openDashboard = useSelector((state) => state.ui.openDashboard);
  const loggedIn = useSelector((state) => state.ui.isLoggedIn);
  // ************************Close Select **********************
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          dispatch(
            uiActions.showMenu({
              isShoMenu: false,
            })
          );
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const onDashPopup = (id) => {
    if (id === 1) {
      dispatch(
        uiActions.isProfileOpen({
          open: true,
        })
      );
    }
    if (id === 2) {
      navigate("/wishlists");
    }
    if (id === 3) {
      navigate("/bookings");
    }

    if (id === 4) {
      navigate("/");
      dispatch(
        uiActions.userIsLogout({
          loggedIn: false,
        })
      );
      localStorage.removeItem("userDetails");
      localStorage.removeItem("picture");
      localStorage.removeItem("user");
      localStorage.removeItem("access_token");
      dispatch(
        authAction.clickedOnLogin({
          clicked: false,
        })
      );
    }
  };

  return (
    <div ref={wrapperRef} className={styles.mobileNav}>
      {/* <h2>{i18n.language === "ar" ? "منشأتنا" : "Our Facilities"}</h2> */}
      <div className={styles.mobileNav__topRight}>
        {!loggedIn ? (
          <div onClick={onLoginOpen} className={styles.mobileNav__right__login}>
            <p>{t("login")}</p>
          </div>
        ) : (
          <>
            <div className={styles.mobileNav__right__profile}>
              <img src={profile ? profile : prof} alt="profile" />
            </div>
            {openDashboard && <Dashboard />}
          </>
        )}
        <p>
          {t("hi")}, {user ? user.first_name : t("guest")}
        </p>
      </div>
      {loggedIn && (
        <div className={styles.mobileNav__menu2}>
          <ul>
            <li onClick={() => onDashPopup(1)}>{t("profile.prof")}</li>
            <li
              style={{
                borderRight: "1px solid #fff",
                borderLeft: "1px solid #fff",
              }}
              onClick={() => onDashPopup(2)}
            >
              {t("profile.wishlists")}
            </li>
            <li onClick={() => onDashPopup(3)}>{t("profile.bookings")}</li>
          </ul>
        </div>
      )}
      <div
        style={{ maxHeight: `${loggedIn ? "440px" : "530px"}` }}
        className={styles.mobileNav__menu}
      >
        <Acordian />
      </div>

      {loggedIn && (
        <div className={styles.mobileNav__logout}>
          <Button name={t("profile.logout")} onClick={() => onDashPopup(4)} />
        </div>
      )}
    </div>
  );
};

export default MobileNave;
