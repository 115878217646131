import React, { forwardRef, useEffect, useState } from "react";
import ButtonTwo from "../../../widgets/ButtonTwo";
import Input from "../../../widgets/Input";
import { Skeleton } from "@mui/material";
import styles from "./index.module.scss";
import { endpoints } from "../../../services/endpoints";
import axios from "axios";
import ProgressLoader from "../../../shared/ProgressLoader";
import SweetAlert from "../../../shared/SweetAlert";
import Customization from "../../../modals/Customization";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { onCustomizeDetails } from "../../../services/server";
import { useTranslation } from "react-i18next";

const BookSlot = forwardRef((props, ref) => {
  let { details, detailsLoad } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const [options, setOptions] = useState([]);
  const [showOpt, setShowOpt] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showCustomization, setShowCustomization] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState("");
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [pincode, setPincode] = useState("");
  const [price, setPrice] = useState("");
  const [timeslot, setTimeslot] = useState([]);
  const [filteredPin, setFilteredPin] = useState([]);
  const [selectedCustomization, setSelectedCustomization] = useState([]);
  const [customization, setCustomization] = useState([]);
  const [customeLoading, setCustomeLoading] = useState(true);

  let loc = localStorage.getItem("locDet");
  let location = loc && JSON.parse(loc);

  const dtToday = new Date();

  let month = dtToday.getMonth() + 1;
  let day = dtToday.getDate();
  let year = dtToday.getFullYear();
  if (month < 10) month = "0" + month.toString();
  if (day < 10) day = "0" + day.toString();

  const minDate = year + "-" + month + "-" + day;

  const onGettingPinCode = () => {
    const url = endpoints.home.pincode;
    const data = {
      package_id: details.id,
      city_id: location.id,
    };
    axios
      .post(url, data)
      .then((res) => {
        if (res.data.status) {
          setOptions(res.data.body);
          setFilteredPin(res.data.body);
          setShowOpt(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    onGettingPinCode();
    // eslint-disable-next-line
  }, [details]);

  const getPincode = (pin) => {
    setLoading(true);
    setPrice(pin.additional_charge);
    setPincode(pin.pincode);
    setSelectedSlot("");
    setSelectedTimeSlot("");
  };

  const userDefinedPincode = (e) => {
    setPincode(e.target.value);
    if (e.target.value.length > 0) {
      const filtered = filteredPin.filter((item) => item.pincode.toString().startsWith(e.target.value));
      setOptions(filtered);
      setShowOpt(true);
    } else {
      setShowOpt(false);
    }
  };

  useEffect(() => {
    const url = endpoints.deatils.timesSlot;
    const tsData = {
      package_id: details.id,
      pincode: pincode,
    };

    if (pincode) {
      axios
        .post(url, tsData)
        .then((res) => {
          if (res.data.status) {
            setTimeslot(res.data.body[0].time);
            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [pincode, details.id]);

  const onSelectTimeSlot = (itm) => {
    setSelectedSlot(itm.id);
    setSelectedTimeSlot(itm.slots);
  };

  const selectDateForDelivery = (e) => {
    setSelectedDate(e.target.value);
  };

  const bookPackage = () => {
    if (pincode === "") {
      SweetAlert("warning", "Pincode is required");
    } else if (selectedDate === "") {
      SweetAlert("warning", "Date is required");
    } else if (selectedTimeSlot === "") {
      SweetAlert("warning", "Time is required");
    } else {
      setShowCustomization(true);
    }
  };

  window.onclick = () => {
    setShowOpt(false);
  };

  const onClose = () => {
    setShowCustomization(false);
  };
  const onHandleBook = () => {
    console.log(selectedDate);
    const val = {
      cityId: location.id,
      objectId: details.id,
      pincode: pincode,
      //   customizationDetails: selectedCustomization,
      timeslotId: selectedSlot,
      booking_date: selectedDate,
      offer_amount: details.discounted_price,
      //   totalPrice: totalPrice,
      timeslot: selectedTimeSlot,
      //   packagePrice: packagePrice,
      gstPrice: details.gst_price,
      deliveryCharge: price,
      //   gstPrice: gstPrice,
      gstPercent: details.gst,
      productTitle: details.title,
      //   deliveryCharge: deliveryCharge,
      img: details.gallery[0],
      // packageIdd: package_id,
      vendorId: details.is_suppierId,
      selectedCustomization: selectedCustomization,
      //   selectedCustomizationId: selectedCustomizationId,
    };
    const path = generatePath("/:location/:prodName/:prodId/booking", {
      location: params.location,
      subCat: params.subCat,
      subId: params.subId,
      prodName: params.name,
      prodId: params.prodId,
    });

    navigate(path, { state: { prodDetails: val } });
  };

  const getCustomization = async () => {
    setCustomeLoading(true);
    const data = {
      package_id: details.id,
    };

    const res = await onCustomizeDetails(data);
    if (res?.status === true) {
      const val = res.body;
      setCustomization(val);
      setCustomeLoading(false);
    } else if (res?.status === false) {
      setCustomeLoading(false);
    }
  };

  useEffect(() => {
    getCustomization();
    // eslint-disable-next-line
  }, [details.id]);

  let totalPrice = parseInt(price) + parseInt(details.gst_price && details.gst_price.replace(/,/g, ""), 10);

  return (
    <>
      {showCustomization && (
        <Customization
          onHandleBook={onHandleBook}
          onClose={onClose}
          total={totalPrice}
          loading={customeLoading}
          data={customization}
          setSelectedCustomization={setSelectedCustomization}
        />
      )}
      <div className={styles.slot}>
        {detailsLoad ? (
          <Skeleton variant="rectangular" height={45} style={{ margin: "5px 0" }} />
        ) : (
          <div ref={ref} className={styles.slot__title}>
            <h1>{details.title}</h1>
          </div>
        )}
        <div className={styles.slot__price}>
          {detailsLoad ? (
            <Skeleton variant="rectangular" height={40} style={{ margin: "5px 0" }} />
          ) : (
            <h2 className={styles.slot__price__full}>
              ₹{details.discounted_price}
              <span>/</span>
              <del>₹{details.outlay_price}</del>
            </h2>
          )}
        </div>
        <div className={styles.slot__datetime}>
          <div className={styles.slot__datetime__pincode}>
            <div className={styles.slot__datetime__pincode__left}>
              <Input
                type="number"
                placeholder="Enter Delivery Pincode"
                label={t("details.pinCode")}
                value={pincode}
                onChange={userDefinedPincode}
                min="6"
                max="6"
              />
              {showOpt && (
                <ul className={styles.slot__datetime__pincode__left__ul}>
                  {options.length < 1 ? (
                    <li>{t("details.noResult")}</li>
                  ) : (
                    <>
                      {options.map((item) => {
                        return (
                          <li key={item.id} onClick={() => getPincode(item)}>
                            {item.pincode}
                          </li>
                        );
                      })}
                    </>
                  )}
                </ul>
              )}
            </div>
            <div className={styles.slot__datetime__pincode__right}>
              <Input type="date" min={minDate} onChange={selectDateForDelivery} />
            </div>
          </div>
          {price >= 0 && price !== "" && (
            <p>
              {t("details.deliveryCharge")} {price}
            </p>
          )}

          <div className={styles.slot__datetime__timeslot}>
            {loading ? (
              <ProgressLoader color="#000" />
            ) : (
              <ul className={styles.slot__datetime__timeslot__ul}>
                {timeslot.map((item) => {
                  return (
                    <li onClick={() => onSelectTimeSlot(item)} className={styles.slot__datetime__timeslot__ul__li} key={item.id}>
                      <input defaultChecked={item.id === selectedSlot} type="radio" name="timeslot" />
                      <p>{item.slots}</p>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
        <ButtonTwo name={t("details.book")} onClick={bookPackage} />
      </div>
    </>
  );
});

export default BookSlot;
